/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Jomon's Portfolio",
  description:
    "Master’s Student at UPB | Program Analyst",
  og: {
    title: "Jomon George Portfolio",
    type: "website",
    url: "https://jomongeorge.com/",
  },
};

//Home Page
const greeting = {
  title: "Jomon George",
  logo_name: "JomonGeorge",
  // nickname: "jmngeo",
  subTitle:
    "Master’s Student @ UPB | Program Analyst",
  resumeLink:
    "https://drive.google.com/file/d/1pa9X243VGNomNm7uK0R3wVuMZ-IkxwFv/view?usp=sharing",
    SemesterTicketLink: "https://drive.google.com/file/d/1r2dmXN5pZbOw6Fe48Tob0bTe_PEkjW0K/view?usp=sharing",
    portfolio_repository: "https://github.com/jmngeo/jmngeo.github.io",
  githubProfile: "https://github.com/jmngeo",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/jmngeo",
  // linkedin: "https://www.linkedin.com/in/jmngeo/",
  // gmail: "@gmail.com",
  // gitlab: "https://gitlab.com/jmngeo",
  // facebook: "https://www.facebook.com/",
  // twitter: "https://twitter.com/",
  // instagram: "https://www.instagram.com/"

  {
    name: "Github",
    link: "https://github.com/jmngeo",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/jmngeo/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Mail",
    link: "mailto:jmngeo@protonmail.com",
    // link: "mailto:jomon.george@hotmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#28A8EA", // Reference https://simpleicons.org/?q=gmail
  },
];

const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building resposive website front end using React",
        "⚡ Creating application backend in Node",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
      ],
    },
    {
      title: "Automation Testing",
      fileName: "DataScienceImg",
      skills: [
       "⚡ Developing automation scripts for integration testing using Selenium and Webdriver",
       "⚡ Developing error validation scripts",
       "⚡ Test report logs and summary",
      ],
      softwareSkills: [
        {
          skillName: "Selenium",
          fontAwesomeClassname: "simple-icons:selenium",
          style: {
            color: "#43B02A",
          },
        },
        {
          skillName: "Apache maven",
          fontAwesomeClassname: "simple-icons:apachemaven",
          style: {
            color: "#C71A36",
          },
        },
        {
          skillName: "Jenkins",
          fontAwesomeClassname: "simple-icons:jenkins",
          style: {
            color: "#D24939",
          },
        },
        {
          skillName: "Java",
          fontAwesomeClassname: "simple-icons:java",
          style: {
            color: "#007396",
          },
        },
        {
          skillName: "Subversion",
          fontAwesomeClassname: "simple-icons:subversion",
          style: {
            color: "#809CC9",
          },
        },
      ],
    },
    {
      title: "Game Development",
      fileName: "DesignImg",
      skills: [
        "⚡ Serious Game development using Unity and C#",
        "⚡ Online Multiplayer game development using Photon",
      ],
      softwareSkills: [
        {
          skillName: "Unity",
          fontAwesomeClassname: "iconoir:unity",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "C#",
          fontAwesomeClassname: "devicon:csharp",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Unreal Engine",
          fontAwesomeClassname: "fontisto:unreal-engine",
          style: {
            backgroundColor: "transparent",
          },
        },
      ],
    },

  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
  ],
};

const degrees = {
  degrees: [
    {
      title: "Paderborn University, Germany",
      subtitle: "Masters in Computer Science",
      logo_path: "UPB_Logo.png",
      alt_name: "Uni Paderborn",
      duration: "2021 - present",
      descriptions: [
        "⚡ Software Engineering: Model-Based Systems Engineering, Data-Driven Innovation and Engineering",
        "⚡ Intelligence and Data: 	Planning and Heuristic Search, Computational Argumentation, Data Science in Industrial Applications",
        "⚡ Seminar on Secure Systems Engineering:  Unified Points-to and Taint Analysis",
        "⚡ Masters Project: Serious Game development using Unity and C#",
      ],
      website_link: "https://www.uni-paderborn.de/en/university/",
    },
    {
      title: "Model Engineering College, India",
      subtitle: "B.Tech. in Computer Science and Engineering",
      logo_path: "MEC_logo.png",
      alt_name: "MEC",
      duration: "2015 - 2019",
      descriptions: [
       "⚡ I have studied basic software engineering subjects like Data Structres, Algorithms, DBMS, OS, AI, etc.",
       "⚡ As part of Final Year project, I have worked on Video anomaly detection using image processing techniques and a detailed comparison with ROC curves of the following Classifiers: KNN, RBF-SVM, Naive Bayes, AdaBoost, Decision Tree, Random Forest, and Neural Networks.",
      ],
      website_link: "https://www.mec.ac.in",
    },
  ],
};

const certifications = {
  certifications: [
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship",
  description:
    "I have worked on web application automation testing using Java and Selenium. I have also worked on chatbot development using SAP UI5 and Firebase as a Software Developer Intern.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      experiences: [
        {
          title: "Program Analyst",
          company: "Cognizant Technology Solutions",
          company_url: "https://www.cognizant.com/",
          logo_path: "CognizantLogo.png",
          duration: "December 2019 - April 2021",
          location: "Coimbatore, Tamil Nadu",
          description:[
            "⚡ Involving in Testing and Support of Insurance Software Products\n",
            "⚡ Involving in the design of end to end test cases - Script/ Scenario creation",
            "⚡ Developing automation scripts for integration testing using Selenium, TestNG, Maven, and Webdriver",
            "⚡ Test Execution and production support",
            "⚡ Delivering business demos",
            "⚡ Developing error validation scripts",
            "⚡ Validating APIs using Postman",
            "⚡ Test Analysis and test report",
            "⚡ Test report logs and summary",
          ],
          color: "#0879bf",
        },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Software Developer Intern",
          company: "Actionfi technologies",
          company_url: "https://www.actionfi.com/",
          logo_path: "ActionfiLogo.png",
          duration: "June 2018 - July 2018",
          location: "Kozhikode, Kerala",
          description:[
            "⚡ Developing Customer Service Chatbot Application - chatbox extension through which the user communicates in real-time with the service agent using SAP tools (SAP UI5), Firebase, and Javascript.",
            "⚡ Developing an administrator chat window for the service agent.",
          ],
          color: "#7399c6",
        },
      ],
    },
  ],
};


// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "",
    description: "I am available through the following media. Please feel to reach out to me through Email or LinkedIn.", //making this description short will cause alignment issues in the Contact Page.
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
  // phoneSection: {
  //   title: "Phone Number",
  //   subtitle: "+49 15758903851",
  // },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  contactPageData,
};
